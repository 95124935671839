/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { BRAND_WOWCHER } from '../../../config/setup/setup';
import { isMobileViewport, useScreenBreakpoint } from '../../../helpers/screen';
import ThemeContext from '../../../providers/ThemeProvider';

/**
 * Rounded primary button with variants
 *
 * .full-width        span the full width of container
 * .inverse-button    white background, main colour text
 * .outline           main colour text and outline, white background;
 * .italic            make text italic
 * .align-left        make text align left
 * .fit-content       make text fit content
 * .font-weight-normal make the font-weight normal
 * .size-{large | xlarge | small } size of the general button
 * .inline            make the button display inline
 */
const PrimaryButton = ({
  ariaDisabled = false,
  ariaDisabledReason,
  onClick = () => {},
  buttonRef,
  id,
  style,
  className = '',
  ariaLabel,
  children,
  disabled = false,
  tag = 'button',
  type,
}) => {
  const theme = useContext(ThemeContext);
  const site = process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER;
  const CustomTag = tag;

  const [isMobile, setIsMobile] = useState(false);

  const breakpoint = useScreenBreakpoint();

  const handleClick = () => {
    if (!ariaDisabled) onClick();
  };

  useEffect(() => {
    setIsMobile(isMobileViewport());
  }, [breakpoint]);

  return (
    <>
      <CustomTag
        aria-describedby={ariaDisabled ? 'disabledReason' : ''}
        aria-disabled={ariaDisabled}
        aria-label={ariaLabel}
        className={classNames(
          { lowercase_text: site !== BRAND_WOWCHER },
          'primary-button',
          className,
        )}
        disabled={disabled}
        id={id}
        onClick={handleClick}
        ref={buttonRef}
        style={style}
        type={type || 'button'}
      >
        {children}
        {ariaDisabled && (
          <div className="disabled-tooltip" id="disabledReason" role="tooltip">
            <span className="tooltipItself">{ariaDisabledReason}</span>
          </div>
        )}
      </CustomTag>

      <style jsx>{`
        .primary-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${theme.colors.dealviewbutton};
          text-align: center;
          color: #ffffff;
          font-weight: bold;
          font-size: 18px;
          border-radius: 60px;
          border: none;
          text-decoration: none;
          text-transform: uppercase;
          outline: none;
          min-width: 100px;
          min-height: 40px;
          padding: 7px 20px;
        }
        .savings-button {
          border-radius: 60px;
          display: inline-block;
          font-weight: 700;
          font-size: ${isMobile ? '24px' : '26px'};
          margin-bottom: 4px;
          min-height: 42px;
          padding: 10px 16px;
          text-transform: ${theme.text.cta.button};
          white-space: nowrap;
          background-color: ${theme.colors.primary};
        }
        .primary-button:focus {
          text-decoration: underline;
        }
        .primary-button.inline {
          display: inline;
        }
        .primary-button.full-width {
          width: 100%;
        }
        .primary-button.invert {
          background-color: #ffffff;
          color: ${theme.colors.dealviewbutton};
        }
        .primary-button.outline {
          color: ${theme.colors.dealviewbutton};
          border: 3px solid ${theme.colors.dealviewbutton};
          background-color: #ffffff;
          padding: 4px 17px;
        }
        .primary-button.thin-outline {
          border-width: 1px;
        }
        .primary-button.primary-color {
          color: ${theme.colors.primary};
          border: 3px solid ${theme.colors.primary};
          background-color: #ffffff;
          padding: 4px 17px;
        }
        .primary-button.light-blue-color {
          background-color: ${theme.commoncolors.buttonpaylight};
          color: #000000;
        }
        .primary-button.grey-color {
          background-color: #c7c7c7;
          color: #000000;
        }
        .primary-button.capitalize {
          text-transform: capitalize;
        }
        .primary-button.fit-content {
          width: fit-content;
        }
        .primary-button.align-left {
          align-items: flex-start;
          justify-content: start;
          text-align: left;
        }
        .primary-button.font-weight-normal {
          font-weight: normal;
        }
        .primary-button.italic {
          font-style: italic;
        }
        .primary-button.margin {
          margin-bottom: 8px;
        }
        .primary-button:disabled,
        .primary-button[aria-disabled='true'] {
          color: #ffffff;
          cursor: not-allowed;
          background-color: #cccccc;
        }
        .primary-button.outline:disabled {
          color: #ffffff;
          border: 3px solid #cccccc;
        }
        .opacity_seventy {
          opacity: 70%;
        }
        .lowercase_text {
          text-transform: lowercase;
        }
        .inherit-text {
          text-transform: inherit;
        }
        .primary-button.modal-sticky {
          position: sticky;
          bottom: 0;
          z-index: 10;
        }
        .primary-button.less-rounded {
          border-radius: 6px;
        }

        // various sizes
        .primary-button.size-largest-mobile-only {
          font-size: 28px;
          min-height: 55px;
        }
        .primary-button.size-largest {
          font-size: 28px;
          min-height: 55px;
        }
        .primary-button.size-medium {
          font-size: 16px;
          line-height: 16px;
          min-height: 40px;
          padding: 6px 20px;
        }
        .primary-button.size-medium-small {
          font-size: 14px;
          line-height: 14px;
          min-height: 34px;
          padding: 6px 15px;
        }
        .primary-button.size-small {
          font-size: 12px;
          min-height: 25px;
          padding: 6px 12px;
        }
        @media only screen and (max-width: ${theme.breakpoints.smUp}) {
          .primary-button.size-small {
            font-size: 10px;
            min-height: 25px;
            padding: 6px 12px;
          }
          .primary-button.size-small-mobile-only {
            font-size: 12px;
            min-height: 20px;
            padding: 3px 10px;
          }
        }
        .primary-button.size-smallest {
          font-size: 12px;
          min-height: 25px;
          padding: 6px 12px;
        }
        @media only screen and (max-width: ${theme.breakpoints.smUp}) {
          .primary-button.size-smallest {
            font-size: 16px;
            min-height: 38px;
            min-width: 306px;
            padding: 6px 12px;
          }
        }
        .primary-button.size-smaller {
          font-size: 11px;
          min-height: 18px;
          min-width: unset;
          padding: 3px 11px;
          position: relative;
        }
        @media only screen and (min-width: ${theme.breakpoints.smUp}) {
          .primary-button.size-largest-mobile-only {
            font-size: 20px;
            min-height: 40px;
          }
        }
        @media only screen and (min-width: ${theme.breakpoints.smUp}) {
          .primary-button.size-largest {
            font-size: 35px;
            min-height: 40px;
          }
        }
        .primary-button.size-xlarger {
          font-size: 22px;
          min-height: 40px;
        }
        @media only screen and (max-width: ${theme.breakpoints.smUp}) {
          .primary-button.size-xlarger {
            font-size: 16px;
            min-height: 42px;
            min-width: 350px;
          }
        }
        .primary-button.size-larger {
          font-size: 24px;
          min-height: 55px;
        }
        @media only screen and (min-width: ${theme.breakpoints.smUp}) {
          .primary-button.size-larger {
            min-height: 40px;
          }
        }
        .primary-button.size-large {
          font-size: 20px;
          min-height: 55px;
        }
        @media only screen and (min-width: ${theme.breakpoints.smUp}) {
          .primary-button.size-large {
            min-height: 40px;
          }
        }
        .primary-button.size-xlarge {
          font-size: 25px;
          min-height: 40px;
        }
        // keep the text on two lines
        @media only screen and (min-width: ${theme.breakpoints.smUp}) {
          .primary-button.choose-your-options-button {
            padding: 7px 15px;
          }
        }

        @media (min-width: ${theme.breakpoints.lgUp}) {
          .primary-button.size-xlarge {
            min-height: 72px;
            font-size: 30px;
          }
        }

        @media (min-width: ${theme.breakpoints.xlDown}) {
          .primary-button.size-xlarge {
            min-height: 80px;
          }
        }
        .primary-button.national-deal {
          cursor: default;
        }
        // must have alternative visible focus if using this
        .no-focus:focus {
          text-decoration: none;
        }
        .disabled-tooltip {
          display: none;
        }
        .primary-button:focus .disabled-tooltip,
        .primary-button:hover .disabled-tooltip,
        .primary-button:active .disabled-tooltip {
          background-color: black;
          border-radius: 6px;
          color: white;
          display: block;
          font-size: 11px;
          padding: 5px 5px;
          position: absolute;
          text-transform: none;
        }
        .button-margin {
          margin: 0 20px;
        }
      `}</style>
    </>
  );
};

PrimaryButton.propTypes = {
  ariaDisabled: PropTypes.bool,
  ariaDisabledReason: PropTypes.string,
  ariaLabel: PropTypes.string,
  buttonRef: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tag: PropTypes.string,
  type: PropTypes.string,
};

export default PrimaryButton;
