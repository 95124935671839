import { PLACEHOLDER_IMG } from '../config/constants/images';
import { REDEEM_IMAGES, REDEEM_IMAGE_TYPES } from '../config/links/links';
import {
  IMAGES_DEAL_URL,
  DEFAULT_DEAL_IMG_WIDTH,
  DEFAULT_DEAL_IMG_HEIGHT,
  STATIC_IMAGES_URL,
} from '../config/setup/setup';
import {
  getIsLeadGen,
  getIsCalendarDeal,
  getIsCYFDeal,
  getIsMhu,
} from './deals';
import { getQuality } from './getQuality';

/*
Replace static url with sized image server url
e.g. https://static.wowcher.co.uk/images/deal/15996574/4034447.jpg
-> https://images.wowcher.co.uk/images/deal/60x40/15996574/4034447.jpg
or -> https://images09.devwowcher.co.uk/images/deal/60x40/15996574/4034447.jpg
 */

export function replaceStaticWithImageServer({
  extension,
  height = DEFAULT_DEAL_IMG_HEIGHT,
  staticUrl,
  width = DEFAULT_DEAL_IMG_WIDTH,
}) {
  if (!staticUrl || !extension) return '';

  // replace base url
  let imgUrl = staticUrl.replace(
    'https://static.wowcher.co.uk',
    `${
      IMAGES_DEAL_URL[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
        process.env.NEXT_PUBLIC_SITE || 'wowcher'
      ]
    }`,
  );
  // add sizing
  imgUrl = imgUrl.replace(/(\d+)\/(\d+)/, `$1/${width}x${height}/$2`);
  // add file extension
  imgUrl += `.${extension}`;

  return imgUrl;
}

export const replaceTileImageServer = ({ imageUrl, height, width }) => {
  if (process.env.NEXT_PUBLIC_USE_CDN === 'true') {
    const quality = getQuality();

    return `https://cfimg.wowcher.co.uk/cdn-cgi/image/height=${height},width=${width},quality=${quality},format=auto/${imageUrl}`;
  }

  return imageUrl;
};

export const replaceImageServer = ({
  dealId,
  height = DEFAULT_DEAL_IMG_HEIGHT,
  image,
  isSubSubCat = false,
  width = DEFAULT_DEAL_IMG_WIDTH,
}) => {
  if (!image || !dealId) return null;

  const imageId = image.id || '';
  const imageExtension = image.extension || '';

  const quality = getQuality(isSubSubCat);

  return `https://cfimg.wowcher.co.uk/cdn-cgi/image/height=${height},width=${width},quality=${quality},format=auto/${
    STATIC_IMAGES_URL[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
      process.env.NEXT_PUBLIC_SITE || 'wowcher'
    ]
  }/images/deal/${dealId}/${imageId}.${imageExtension}`;
};

export const usePlaceholderImage = (event) => {
  event.target.onerror = null;
  event.target.src = PLACEHOLDER_IMG[process.env.NEXT_PUBLIC_SITE || 'wowcher'];
};

export function replaceImageLogo(dealId, item) {
  if (!item) return '';

  const imageId = item.id || '';
  const imageExtension = item.extension || '';
  const height = '73';
  const width = '161';

  if (process.env.NEXT_PUBLIC_USE_CDN === 'true') {
    return `https://cfimg.wowcher.co.uk/cdn-cgi/image/height=${height},width=${width},quality=85,format=auto/${
      STATIC_IMAGES_URL.prod[process.env.NEXT_PUBLIC_SITE || 'wowcher']
    }/images/business/${dealId}/${imageId}-logo.${imageExtension}`;
  }

  if (item.imageUrl) return `${item.imageUrl}-logo.${imageExtension}`;

  return `${
    IMAGES_DEAL_URL[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
      process.env.NEXT_PUBLIC_SITE || 'wowcher'
    ]
  }/images/business/${dealId}/${imageId}-logo.${imageExtension}`;
}

export const getRedeemImageSource = (deal, details) => {
  let type = REDEEM_IMAGE_TYPES.standard;

  if (details.isTravel) {
    type = REDEEM_IMAGE_TYPES.standardTravel;

    if (getIsLeadGen(deal)) {
      type = REDEEM_IMAGE_TYPES.callToBook;
    }

    if (getIsCalendarDeal(deal)) {
      type = REDEEM_IMAGE_TYPES.calendar;
    }

    if (getIsCYFDeal(deal)) {
      type = REDEEM_IMAGE_TYPES.chooseYourFlight;
    }
  }

  if (getIsMhu(deal)) {
    type = REDEEM_IMAGE_TYPES.mysteryTravel;
  }

  return REDEEM_IMAGES[process.env.NEXT_PUBLIC_BRAND][type];
};
